import "./blackFriday.scss";
import Layout from "../../layout/Layout";
import AccordeonComponent from "../../components/accordeon/AccordeonComponent";
import { Form } from "./components/form";
import CommenstLineComponent from "../../components/commentsLine/CommentsLineComponent";
import { Link } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import Icons from "../../assets/Icons";
import { useEffect, useState } from "react";
import HostApi from "../../../../data/settings/HostApi";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";

export const BlackFriday = () => {
    const [outstandingBlog, setOutstandingBlog] = useState(undefined);

    useEffect(() => {
        _loadBlogs();
    }, []);

    const _loadBlogs = async () => {
        try {
            const response = await HostApi.get("/post");
            const mainPostData = await response.main_post;
            setOutstandingBlog(mainPostData);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Layout>
            <div className="workshop_and_service">
                <section className="workshop_and_service_banner">
                    <img className="position-absolute banner" src="/assets/blackFriday/fondo.png" alt="WCAR" />
                    <div className="banner_opacity position-absolute"></div>
                    <div className="block" style={{ height: "100vh", display: "block" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "90%",
                                justifyContent: "space-between",
                            }}
                            className="responsive-container"
                        >
                            <div>
                                <img
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "220px",
                                        objectFit: "contain",
                                    }}
                                    className="logo-black-friday banner"
                                    src="/assets/blackFriday/logo-orange.png"
                                    alt="WCAR"
                                />
                            </div>
                            <div>
                                <img
                                    style={{
                                        maxWidth: "400px",
                                        maxHeight: "220px",
                                        objectFit: "contain",
                                        width: "100%",
                                    }}
                                    className="logo-black-friday banner"
                                    src="/assets/blackFriday/fechas.png"
                                    alt="WCAR"
                                />
                            </div>
                        </div>
                        <img
                            style={{
                                maxWidth: 700,
                                maxHeight: 500,
                                objectFit: "contain",
                                marginLeft: "10%",
                            }}
                            className=" logo-black-friday banner"
                            src="/assets/blackFriday/carro.png"
                            alt="WCAR"
                        />
                        <div style={{ width: "100%", maxWidth: 700, marginTop: 20 }}>
                            <h4 style={{ color: "white", textAlign: "center" }}>
                                Los mejores descuentos de <br />
                                autos usados inician en:
                            </h4>
                            <div
                                style={{
                                    display: "flex",
                                    gap: 10,
                                    marginTop: 20,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    justifyContent: "center",
                                }}
                            >
                                <div>
                                    <div
                                        style={{
                                            width: 70,
                                            height: 70,
                                            borderWidth: 2,
                                            borderStyle: "solid",
                                            borderColor: "#00FEFE",

                                            alignItems: "center",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <h4 style={{ fontSize: 50, color: "white", textAlign: "center", marginTop: 3 }}>
                                            10
                                        </h4>
                                    </div>
                                    <p
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 700,
                                            color: "white",
                                            textAlign: "center",
                                            marginTop: 3,
                                        }}
                                    >
                                        DÍAS
                                    </p>
                                </div>
                                <div>
                                    <div
                                        style={{
                                            width: 70,
                                            height: 70,
                                            borderWidth: 2,
                                            borderStyle: "solid",
                                            borderColor: "#00FEFE",

                                            alignItems: "center",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <h4 style={{ fontSize: 50, color: "white", textAlign: "center", marginTop: 3 }}>
                                            10
                                        </h4>
                                    </div>
                                    <p
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 700,
                                            color: "white",
                                            textAlign: "center",
                                            marginTop: 3,
                                        }}
                                    >
                                        HORAS
                                    </p>
                                </div>

                                <div>
                                    <div
                                        style={{
                                            width: 70,
                                            height: 70,
                                            borderWidth: 2,
                                            borderStyle: "solid",
                                            borderColor: "#00FEFE",

                                            alignItems: "center",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <h4 style={{ fontSize: 50, color: "white", textAlign: "center", marginTop: 3 }}>
                                            10
                                        </h4>
                                    </div>
                                    <p
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 700,
                                            color: "white",
                                            textAlign: "center",
                                            marginTop: 3,
                                        }}
                                    >
                                        MIN
                                    </p>
                                </div>
                                <div>
                                    <div
                                        style={{
                                            width: 70,
                                            height: 70,
                                            borderWidth: 2,
                                            borderStyle: "solid",
                                            borderColor: "#00FEFE",

                                            alignItems: "center",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <h4 style={{ fontSize: 50, color: "white", textAlign: "center", marginTop: 3 }}>
                                            10
                                        </h4>
                                    </div>
                                    <p
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 700,
                                            color: "white",
                                            textAlign: "center",
                                            marginTop: 3,
                                        }}
                                    >
                                        SEG
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};
